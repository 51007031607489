.home-dark {
  // background: #131416;
  background: black;
  color: #878e99;
  position: relative;
  z-index: 1;

  // scroll-behavior: calc(100% - 80px);
  // scroll-snap-align: start;
  // scroll-snap-type: y mandatory;
  // overflow-y: scroll;
  // height: 100vh;
  // overflow: auto !important;
  // scroll-snap-type: y mandatory !important;
  // scroll-snap-align: start !important;
  // scroll-behavior: smooth !important;
  .about-me .media label {
    color: #efefef;
  }
  .home-banner .bottom-style svg,
  .home-banner .top-style svg {
    color: #483d8b;
  }
  .home-banner .bottom-style.dark-shape svg,
  .home-banner .top-style.dark-shape svg {
    color: #101010;
  }
  .navbar-toggler,
  .toggler-menu {
    background: transparent;
  }
  .author-signeture {
    filter: invert(0.55);
  }
  .feature-box-01,
  .feature-box-02,
  .blog-post .blog-info {
    // background: #131416;
    background: rgba($color: #272727, $alpha: 0.6);
  }
  .feature-box-02 {
    h6 {
      color: rgba(255, 255, 255, 0.65);
    }
    h5 {
      color: #fff;
    }
  }
  .main-header.fixed-header {
    background: #1c1d24;
  }
  .fixed-header.main-header {
    border-color: #2a2a2a;
    .navbar-nav > li.current a {
      color: #688694;
    }
  }



  // add MODAL SHIT
  .modal-window {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.25);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    color: white;
    &:target {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
    & > div {
      border-radius: 20px;
      width: 890px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 2em;
      background: black;
    }
    header {
      font-weight: bold;
    }
    h1 {
      font-size: 150%;
      margin: 0 0 15px;
    }
  }
  
  .modal-close {
  
    color: #aaa;
    line-height: 50px;
    font-size: 80%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 70px;
    text-decoration: none;
    &:hover {
      color: black;
    }
  }


    // my adition to modal
    .terms{
      overflow-y: scroll;
      width: 800px;
      height: 500px;
    }
    // {{overflowY:'scroll', width:'800px', height:'500px', borderRadius:'10px'}}



    .pure-modal .panel-body{background-color:#272727; height: 500px;}
    .pure-modal{border-radius: 20px;}
    .pure-modal .panel-heading{background:#272727}
    .pure-modal .close{
      right:10px;top:10px;z-index:1;background:#272727;width:25px;color:#878e99;transition:color .1s ease-in-out;font-weight: bold;
      height:25px;border-radius:4px;text-align:center;line-height:25px;cursor:pointer
    }
      .pure-modal .close:hover{
        // background-color: #272727;
        // color: white;
        right:10px;top:10px;z-index:1;background:#688694;width:25px;color:#272727;transition:color .1s ease-in-out;font-weight: bold;
      height:25px;border-radius:4px;text-align:center;line-height:25px;cursor:pointer
      }
   


// CHNAGE THE TEXT IN SMALL



.bio_text{
  word-spacing: 3px;
  line-height: 2;
  text-align: justify;
  word-spacing: 1;
  font-size: 18px;
  padding-right: 40px;
  padding-left: 10px;
}
.biotech{
  display: flex !important;
  justify-content: space-around !important;
  width: 60% !important;
  border-radius: 20px !important;
  margin: auto !important;
  background-color: rgba(27,27,27,0.8) !important;
}

// style={{display:'flex', justifyContent:'space-around', width:'60%',borderRadius:'20px',margin:'auto',backgroundColor:'rgba(27,27,27,0.8)'}}
// style={{wordSpacing:'3px',lineHeight:'2', textAlign:'justify', fontSize:'18px', paddingRight:'40px', paddingLeft:'10px'}}

// about section

.image-sizing{
  height: 110vh;
  background-size: cover;
  background-position:center bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  enable-background: 0vmax;
  width: 100%;
  // display: flex;
  // justify-content: center;
}

// , backgroundSize:'100%',backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundAttachment:'fixed', display:'flex', justifyContent:'center',

// SAMPLE SECTION ADDITIONS
.player-content{
  width: 650px;

  // height: 100vh;
}
.sample-section{
  height: 110vh;
  // background-image:;
  background-size: 80%;
  background-repeat: no-repeat;
  background-color: #000;
  background-position-y: 90px;
  background-position-x: -280px;
  background-attachment: fixed;
}

.lol{
  background-color: #483d8b;
}

// checkbox styling
input[type="checkbox"]{
  appearance: none;
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  background-color: #f5f5f5;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type="checkbox"]::after{
  content: "✓";
  color: white;
  font-weight: bold;
  display: none;
 
}
input[type="checkbox"]:hover{
  border-radius: 5px;
  border: 1px solid white;
  background-color: #575555 !important;
}
input[type="checkbox"]:checked{
  background-color: #688694 !important;
}
input[type="checkbox"]:checked:after{
  // background-color: hotpink;
  display: block;
  outline: none;
}



.about-section .typo-box h3,
  .about-section .typo-box h5,
  .testmonial-box .testmonial-info h6,
  .fixed-header .navbar-brand,
  .fixed-header .navbar-nav > li > a,
  .section-title h3,
  .skill-lt h6,
  .sm-title h4,
  .portfolio-filter-01 .filter li.react-tabs__tab,
  .feature-box-01 h5,
  .blog-post .blog-info h6,
  .contct-form .form-group .form-label,
  .contct-form .form-group .form-control,
  .portfolio-box-01 h5 a {
    color: #fff;
  }


  .contact-info .media .media-body {
    // color: #878e99;
    color: #99a6b9;
  }
  .gray-bg,
  .contct-form {
    background: #1c1d24;
  }
  .contct-form .form-group .form-control,
  .contct-form .form-group .form-label,
  .portfolio-box-01 {
    background: #131416;
  }
  .contct-form .form-group .form-control {
    border-color: #1a1a1a;
  }
  .portfolio-img {
    img {
      opacity: 0.8;
    }
  }

  .after-left-section:after {
    background: #1a1a1a;
  }
  .dark-img-box {
    background: #232323;
  }










// my media for screen max 1780
@media screen and (max-width:1780px){
  .sample-section{
    height: 100%;
  }
  

// my media for screen max 1780
@media screen and (max-width:1441px){

  .biotech{
    display: flex !important;
    justify-content: space-around !important;
    width: 80% !important;
    border-radius: 20px !important;
    margin: auto !important;
    background-color: rgba(27,27,27,0.8) !important;
  }


  .image-sizing{
    height: 100%;
  }
  
}

// style={{position:'absolute', bottom:'20px', textAlign:'center', width:'100%'}}

  @media screen and (max-width: 991px) {


    
    .upsos{
      height: 150px;
    }


    // .btn-bar{
    //   position: absolute !important;
    //   bottom: 20px !important;
    //   text-align: center !important;
    //   width: 100% !important;
    // }

    .check-margin{
      // background-color: greenyellow;
      padding: 0;
      margin: 0;
    }

    .bio_text_container{
      padding: 0;
      margin: 0;
      
    }


    .first-sect{
      height: 100vh;
     
    }

    // from the template css
    .fixed-header .header-transparent {
      background-color: rgba(27,27,27,0);
    }

    .loop-text{
      font-size: 18px;
      // background-color: red;
      display: block;
      text-align: center;
      margin: auto;
      
      // margin: auto;
      // text-align: center;
      // margin: auto;
    }

  .h1-mobile{
    text-align: center !important;
    margin-top: 370px;
    line-height: 14px;
    font-size: 18px !important;
  }


    .font-alt{
      // font-size: 26px !important;
      // align-self: flex-end;
      // position:absolute;
      // bottom: 80px;
      text-align: left;
      // margin-top: 350px;
      // vertical-align: bottom !important;

    }

    .btn-md{
      
      font-size: 12px !important;
      width: 135px;
      text-align: center !important;
      margin: auto !important;
      border-radius: 10px;
      word-break: normal !important;
    }
    .send-btn{
      padding: 6px !important;
      border-radius: 5px;
      font-size: 14px;
      line-height: 30px;
    }

    // .px-btn-theme{
    //   font-size: 12px !important;
    //   width: 180px;
    //   line-height: 40px;
    //   // margin: 0 !important;
    //   padding: 0 !important;
    //   border-radius: 10px;
    // }




    .image-sizing{
      background:#000;
      // background-size: cover;
      height: 100%;
      // background-position: 0vmax;
      background-position: 10%;
      // background-attachment: fixed;
      // background: url('https://www.dmathmusic.com/colfil.png');
      // background-image: url('https://www.dmathmusic.com/color.jpg');
      // background-image:url('./color.jpg');

      background-repeat: no-repeat;
      // background-origin: border-box;
      // background-origin: ;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      // background-size: 250%;
      background-size: cover;
      // background-origin: padding-box;
      background-attachment: scroll;
      // -webkit-mask-attachment: scroll;
      // overflow: hidden;
      // background-position: -620px;
      // transform: rotate(270deg);
    }


    .image-sizing::after{
      content:"";
      position:fixed; /* stretch a fixed position to the whole screen */
      top:0;
      height:100vh; /* fix for mobile browser address bar appearing disappearing */
      left:0;
      right:0;
      z-index:-1; /* needed to keep in the background */
      // background: url('./') center center;
      background-position: center;
      // background
      background-attachment: scroll;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }



    .sample-section{
      // background-size: cover;
      // position: fixed;
      height: 100%;
      width: 100%;
      overflow: hidden;
      background-position: center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-attachment: scroll;
      
      // top:0;
      // // height:100vh; /* fix for mobile browser address bar appearing disappearing */
      // left:0;
      // right:0;
      
      // background-position-y: 0px;
      // background-position-x: 0px;
      // background-color: yellowgreen;
      // background: none;
      // background-origin: none;
      // background:url('./photo1.jpg');
      // background-image: url('../main/');
    }

    .sample-section::after{
      content:"";
      position:fixed; /* stretch a fixed position to the whole screen */
      top:0;
      height:100vh; /* fix for mobile browser address bar appearing disappearing */
      left:0;
      right:0;
      z-index:-1; /* needed to keep in the background */
      // background: url('./') center center;
      background-position: center;
      // background
      background-attachment: scroll;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }


    .player-content{
      width: 100%;
      // overflow: hidden;
    }

    .bio_text{
      word-spacing: normal;
      line-height: normal;
      text-align: center;
      font-size: 17px;
      padding-right: 40px;
      padding-left: 10px;
      overflow: hidden;

      width: 100%;
    }

    .biotech{
      
      display: flex !important;
      justify-content: space-around !important;
      width: 100% !important;
      border-radius: 20px !important;
      margin: auto !important;
      // background-color: transparent !important;
    }
  }

  .main-header.fixed-header .navbar-brand {
    filter: invert(0);
  }
  @media (min-width: 991px) {
    .main-header {
      border-bottom-color: rgba(255, 255, 255, 0.15);
    }
    .main-header .navbar-nav > li {
      border-right-color: rgba(255, 255, 255, 0.15);
    }
    .main-header .navbar-nav > li {
      border-left-color: rgba(255, 255, 255, 0.15);
    }
  }
  .fixed-header .navbar-nav > li {
    border-right-color: #2a2a2a;
    border-left-color: #2a2a2a;
  }
  .about-section .typo-box h3,
  .about-section .typo-box h5,
  .testmonial-box .testmonial-info h6,
  .fixed-header .navbar-brand,
  .fixed-header .navbar-nav > li > a,
  .section-title h3,
  .skill-lt h6,
  .sm-title h4,
  .portfolio-filter-01 .filter li.react-tabs__tab,
  .feature-box-01 h5,
  .blog-post .blog-info h6,
  .contct-form .form-group .form-label,
  .contct-form .form-group .form-control,
  .portfolio-box-01 h5 a {
    color: #fff;
  }
  .portfolio-box-01 {
    &:hover h5 a {
      color: $px-theme;
    }
  }
  .blog-post .blog-info h6 {
    &:hover {
      color: $px-theme;
    }
  }
  .contact-info .media .media-body {
    // color: #878e99;
    color: #99a6b9;
  }
  .gray-bg,
  .contct-form {
    background: #1c1d24;
  }
  .contct-form .form-group .form-control,
  .contct-form .form-group .form-label,
  .portfolio-box-01 {
    background: #131416;
  }
  .contct-form .form-group .form-control {
    border-color: #1a1a1a;
  }
  .portfolio-img {
    img {
      opacity: 0.8;
    }
  }

  .after-left-section:after {
    background: #1a1a1a;
  }
  .dark-img-box {
    background: #232323;
  }



  @media (max-width: 380px) { 
    .upsos{
      height: 10px;
    }

    .image-sizing{
      height: 100%;
    }
  }


  
// samples page correction



  
// style={{backgroundImage:"url(./photo4.jpg)", backgroundSize:'80%', backgroundRepeat:'no-repeat', backgroundColor:'#000', backgroundPositionY:'90px', backgroundPositionX:'-280px',
//   backgroundPosition:'left', backgroundAttachment:'fixed'}}


  @media (max-width: 991px) {


   
    .main-header .navbar-nav > li {
      border-top-color: #333;
      border-right: none;
    }
    .main-header .navbar-nav > li > a {
      color: #fff;
    }
    .main-header .navbar-nav {
      background: #1c1d24;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background: #1c1d24;
        width: 100%;
        height: 60px;
        top: -60px;
      }
    }
  }
  .blog-post .blog-info h6 a {
    &:hover {
      color: $px-theme;
    }
  }
}




}